import React, { useEffect, useRef } from "react";
import { MaskedEmail } from "../../helpers/MaskedEmail";

const PinOtpPopUp = ({
  open,
  setOpen,
  otp,
  setOtp,
  onSubmit,
  onResend,
  error,
  timeLeft,
  setTimeLeft,
}) => {
  const inputsRef = useRef([]);

  useEffect(() => {
    if (inputsRef.current[0] && open) {
      inputsRef.current[0].focus();
    }
  }, [open]);
  const handleChange = (e, index) => {
    const value = e.target.value.replace(/\D/, ""); // Only numbers allowed
    if (!value) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (index < 3 && value) {
      inputsRef.current[index + 1].focus();
    }
  };
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];

      if (otp[index]) {
        // If the current box has a value, just clear it
        newOtp[index] = "";
      } else if (index > 0) {
        // If empty, move back and clear previous box
        newOtp[index - 1] = "";
        inputsRef.current[index - 1].focus();
      }

      setOtp(newOtp);
    }
  };

  useEffect(() => {
    if (timeLeft > 0 && open) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timeLeft, open]);

  return (
    <section
      className={`px-[34px] absolute right-0 left-0 bottom-0 top-[28%] rounded-[31px_31px_0_0] border-[1px] border-[#DADADA] bg-white m-[0_auto] ${
        open ? "block" : "hidden"
      }`}
    >
      <div className=" flex justify-center m-[50px_20px_0] relative">
        <img
          src={"/assets/images/closePop.png"}
          alt=""
          className=" cursor-pointer absolute right-0"
          onClick={() => setOpen(false)}
        />

        <p className=" text-center text-[0.875rem] text-[#2E084D] font-AR">
          OTP Sent!
        </p>
      </div>
      <div className=" mt-[21px] flex flex-col items-center">
        <p className=" text-center text-[12px] text-brandPurp font-SF">
          An otp has been sent to your email
        </p>
        <p className=" text-center text-[12px] text-brandPurp font-SF">
          An OTP has been sent to your registered email address{" "}
          <span>{MaskedEmail()} </span>
          to complete this action.
        </p>
      </div>
      <div className="flex justify-center gap-[20px] pt-[30px]">
        {otp.map((value, index) => (
          <input
            key={index}
            ref={(el) => (inputsRef.current[index] = el)}
            type="text"
            value={value}
            maxLength={1}
            className="w-10 h-10 text-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#592E6D] text-lg"
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
          />
        ))}
      </div>
      <div className="flex justify-end w-full items-center gap-[6px] py-[30px]">
        <button
          className={` text-[12px] text-brandPurp font-[700] ${
            timeLeft === 0 ? "underline" : " disabled:opacity-50"
          }`}
          onClick={() => onResend()}
          disabled={timeLeft !== 0}
        >
          Resend OTP
        </button>

        <span className="text-[12px] text-[#1E1E1E] font-AR font-[700]">
          {timeLeft}s
        </span>
        <img
          src="/assets/images/otpTimer.png"
          alt="timer"
          className="w-[10px] h-[12px]"
        />
      </div>

      <p className="w-full text-left text-[0.9rem] text-[#592E6D] pb-[30px]">
        {error && <p className=" text-[#d32f2f] text-[12px]">{error}</p>}
      </p>
      <button
        className="text-[12px] rounded-lg shadow-lg bg-[#592E6D] text-white w-full px-[10px] py-[10px] mt-[49px] cursor-pointer disabled:opacity-50"
        disabled={otp.some((num) => num === "")}
        onClick={onSubmit}
      >
        Verify OTP
      </button>
    </section>
  );
};

export default PinOtpPopUp;
