import React, { useContext, useState } from "react";
import { Box, Typography, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import NavLayout from "../NavLayout";
import { formstyles } from "../../helpers/Styles";
import {
  useTransactionPin,
  useUserProfile,
  useUserWalletData,
} from "../../helpers/Hooks/Api";
import { Context } from "../../helpers/Context";
import PinOtpPopUp from "./PinOtpPopUp";
import { SuccessPopUp } from "./SuccessPopUp";

const TransactionPin = () => {
  useUserWalletData();
  useUserProfile();
  const navigate = useNavigate();
  const {
    createTransactionPin,
    changeTransactionPin,
    resetTransactionPin,
    serverError,
    showSuccessPopUp,
  } = useTransactionPin();
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm();
  const { state } = useContext(Context);
  const { userWalletData } = state;
  const pinValue = watch("pin");
  const [openOtp, setOpenOtp] = useState(false);
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [timeLeft, setTimeLeft] = useState(60);

  const otpSubmit = async () => {
    const payload = {
      pin: pinValue,
      otp: otp?.join(""),
    };
    await resetTransactionPin(payload, setOpenOtp);
  };

  const onSubmit = async (formData) => {
    userWalletData?.is_set_pin
      ? await changeTransactionPin(formData, setOpenOtp, setTimeLeft)
      : await createTransactionPin(formData);
  };
  return (
    <NavLayout
      title="TransactionPin"
      content={
        <Box
          maxWidth={"500px"}
          sx={{
            backgroundColor: "#fff",
            minHeight: "100vh",
            margin: "0 auto",
            paddingTop: "1px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <img
              src={"/assets/images/close.png"}
              alt=""
              onClick={() => navigate(-1)}
              className=" absolute left-[14px] cursor-pointer"
            />

            <Typography className=" text-brandPurp text-[1.125rem] font-AR">
              {userWalletData?.is_set_pin
                ? "Change Transaction Pin"
                : "Create Transaction Pin"}
            </Typography>
          </Box>

          <Box width={"100%"} px={"20px"} margin={"0px auto"}>
            <Box
              sx={{
                marginTop: "130px",
              }}
            ></Box>

            <form onSubmit={handleSubmit(onSubmit)}>
              <span className="text-[#592E6D]">
                {userWalletData?.is_set_pin
                  ? "Change Transaction Pin"
                  : "Create Transaction Pin"}
              </span>
              <Box
                sx={{
                  borderRadius: "5px",
                  border: "0.5px solid #592E6D",
                  padding: "7px 0 7px 3px",
                  marginBottom: "20px",
                }}
              >
                <TextField
                  required
                  fullWidth
                  type="password"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={formstyles}
                  {...register("pin", {
                    required: "Pin is required",
                    minLength: {
                      value: 4,
                      message: "Pin must be atleast 4 characters",
                    },
                    maxLength: {
                      value: 4,
                      message: "Pin cannot be more than 4 characters",
                    },
                  })}
                  error={!!errors.pin}
                  helperText={errors.pin?.message}
                />
              </Box>

              <span className="text-[#592E6D]">Confirm transaction pin</span>
              <Box
                sx={{
                  borderRadius: "5px",
                  border: "0.5px solid #592E6D",
                  padding: "7px 0 7px 3px",
                  marginBottom: "20px",
                }}
              >
                <TextField
                  required
                  fullWidth
                  type="password"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={formstyles}
                  {...register("confirmPin", {
                    required: "Confirm Pin is required",
                    validate: (value) =>
                      value === pinValue || "Pins do not match",
                  })}
                  error={!!errors.confirmPin}
                  helperText={errors.confirmPin?.message}
                />
              </Box>

              <Box textAlign="left" mb="10px">
                {serverError && (
                  <Typography variant="caption" color="error">
                    {serverError}
                  </Typography>
                )}
              </Box>

              <Box margin="100px auto" width="100%">
                <button
                  type="submit"
                  className=" rounded-[5px] bg-[#592E6D] w-full h-[56px] text-white text-[0.75rem] font-SF font-[600] "
                >
                  {userWalletData?.is_set_pin ? "CHANGE PIN" : "CREATE PIN"}
                </button>
              </Box>
            </form>
          </Box>
          <PinOtpPopUp
            open={openOtp}
            setOpen={setOpenOtp}
            otp={otp}
            setOtp={setOtp}
            onSubmit={otpSubmit}
            onResend={() => onSubmit({ pin: pinValue })}
            error={serverError}
            timeLeft={timeLeft}
            setTimeLeft={setTimeLeft}
          />
          <SuccessPopUp
            open={showSuccessPopUp}
            onClose={() => {
              navigate("/settings");
            }}
            message=
            
              { `You have successfully ${userWalletData?.is_set_pin ? "changed" : "created"} your pin!`} 
            
          />
        </Box>
      }
    />
  );
};

export default TransactionPin;
